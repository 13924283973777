.login-form-wrap {

  .input-label {
    padding: 4px 0;

    .input-text {
      height: 42px;
      padding: 17px 14px 4px;
      font-weight: 400;

      &:focus-visible {
        outline: $outline-focused;
      }

      &.input-password {
        padding-right: 40px;
      }

      &:focus {

        & + .input-placeholder {
          top: 16px;
          font-size: 10px;
          color: #848998;

          &.errored:not(.filled) {
            top: 16px;
          }
        }
      }
    }

    .input-placeholder {
      
      &.filled {
        top: 16px;
      }

      &.errored:not(.filled) {
        top: 25px;
      }
    }

    .show-pass-button {
      padding: 0;
      top: 11px;
      border: none;
      background-color: $transparent;

      &:focus-visible {
        outline: $outline-focused;
      }

      &.hide-pass {

        &::before {
          display: inline-block;
          content: "";
          width: 1px;
          height: 18px;
          position: absolute;
          left: 16px;
          top: 15px;
          background-color: #afb3c1;
          transform: translate(-50%,-50%) rotate(45deg);
          transform-origin: 50% 50% 0;
        }
      }
    }
  }
}

.input-label {
  position: relative;
  padding: 12px 0;
  width: 100%;
  margin-bottom: 0;
  text-align: left;

  .input-text {
    height: 48px;
    width: 100%;
    padding: 18px 14px 11px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: #5e616d;
    background-color: $white;
    border: 1px solid $secondary-disabled;
    border-radius: 8px;
    transition: box-shadow 0.3s ease;
    z-index: 1;
    display: block;

    &:disabled {
      background-color: $lightest-gray;
      border-color: $disabled;
    }

    &:focus,
    &.has-value {
      & + .general-setup-placeholder {
        top: 27px;
        font-size: 10px;
      }
    }

    &:focus {
      box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
        0 10px 40px 0 rgba(239, 239, 239, 0.3);

      & + .general-setup-placeholder {
        color: $secondary-hover;
      }
    }

    &.has-value {
      & + .general-setup-placeholder {
        color: $grey-text;
      }
    }

    &.errored-soft {
      border-color: $light-yellow;
    }

    &.errored-real {
      border-color: $urgent;
      opacity: 0.9;
    }
    &.errored-false {
      border-color: $green-solid;
      opacity: 0.9;
    }
  }

  &:has(input:is(:autofill)) {

    .input-placeholder {
      top: 16px;
      font-size: 10px;
      color: $grey-text;
    }
   
  }
}

.input-placeholder {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 15px;
  font-size: 14px;
  line-height: 17px;
  color: $grey-text;
  transform: translateY(-50%);
  transition: top 0.3s ease, font-size 0.3s ease, line-height 0.3s ease,
    color 0.3s ease;
  user-select: none;
  pointer-events: none;
  z-index: 2;

  &.filled:not(.errored) {
    top: 27px;
    font-size: 10px;
    color: $grey-text;
  }

  &.require {
    color: $urgent !important;
    top: 27px !important;
    font-size: 10px !important;
  }

  &.errored:not(.filled) {
    top: 37px;
  }

  &.errored.filled {
    top: 27px;
    font-size: 10px;
    color: $grey-text;
  }
}

.icon {

  &.icon-right {
    display: inline-block;
    font-size: 20px;
    width: 30px;
    height: 30px;
    text-align: center;
    user-select: none;
    cursor: pointer;
    position: absolute;
    top: 21px;
    right: 10px;
    color: $light-text;
    padding-top: 5px;
    border-radius: 50%;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $scroll;
    }
  }
}

.error-field {
  display: inline-block;
  padding-top: 10px;

  .error-message {
    display: block;
    padding-top: 2px;

    &.error-message-soft {

      .error-icon {
        color: $light-yellow;
        border-color: $light-yellow;
      }
    }

    &.error-message-urgent {

      .error-icon {
        color: $urgent;
        border-color: $urgent;
      }
    }

    &.no-error {

      .error-icon {
        color: $text-color-standard;
        border-color: $no-error;
      }
    }

    .error-icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 5px;
      font-size: 14px;
      line-height: 1;
      color: $urgent;
      border: 1px solid $urgent;
      border-radius: 50%;
      vertical-align: middle;
      text-align: center;

      .material-icons {
        padding: 0;
        font-size: 10px;
        position: relative;
      }
    }

    .error-text {
      display: inline-block;
      color: $text-color-standard;
      font-size: 12px;
      vertical-align: middle;

      &.no-error {
        color: $green-solid;
      }
    }
  }
}
