@import "src/scss/vars.scss";

.generic-label-checkbox {
  display: inline-block;
  padding: 1px 0;
  cursor: pointer;
  // not sure about that - what are the best practices?
  user-select: none;

  // start disabled
  &.disabled {
    cursor: not-allowed;

    &:hover {

      // not checked catches an edge case when the input would be checked and disabled at the same time
      .generic-input-checkbox:not(:checked) + .generic-checkbox {

        .checkbox-inner {

          &:after {
            border-color: $disabled;
          }
        }
      }
    }

    .generic-input-checkbox:checked + .generic-checkbox {

      .checkbox-inner {
        box-shadow: none;

        &::before {
          background-image: linear-gradient(to bottom, $disabled, $disabled);
        }

        &::after {
          border-color: $disabled;
        }

        .checkmark {
          color: $unselected-light;
        }
      }
    }

    .generic-checkbox {

      .checkbox-inner {

        &::before {
          background-image: linear-gradient(to bottom, $disabled, $disabled);
        }

        &::after {
          border-color: $disabled;
        }
      }

      .title {
        color: $unselected-disabled;
      }
    }
  }
  // end disabled

  &:has(.generic-input-checkbox:focus-visible) {

    .checkbox-inner {
      outline: $outline-focused;
    }
  }

  &:hover {

    .generic-input-checkbox + .generic-checkbox {

      .checkbox-inner {

        &::after {
          border-color: $secondary-hover;
        }
      }
    }
  }

  // this has to be with opacity: 0; because if it's with display: none, the focus state is not activated ;/
  .generic-input-checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1;

    // &:focus {

    //   & + .generic-checkbox {

    //     .checkbox-inner {
  
    //       &::after {
    //         border-color: $secondary-hover;
    //       }
    //     }
    //   }
    // }

    &:checked + .generic-checkbox {

      .checkbox-inner {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,0.2), 0 5px 40px 0 rgba(74,116,255,0.3);

        &::before {
          transform: scale(1);
        }

        &::after {
          border-color: $secondary-hover;
        }

        .checkmark {
          color: $temp-gray;
        }
      }
    }
  }

  .generic-checkbox {

    .checkbox-inner {
      display: inline-block;
      position: relative;
      width: 22px;
      height: 22px;
      margin-right: 15px;
      background-color: $transparent;
      border-radius: 4px;
      overflow: hidden;
      transition: box-shadow .3s ease;
      vertical-align: middle;
      outline-offset: 2px;
      z-index: 1;

      &::before,
      &::after {
        display: inline-block;
        content: '';
        position: absolute;
      }
  
      &::before {
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        background-image: linear-gradient(to bottom, $temp-blue01, $temp-blue02);
        border-radius: 50%;
        transform: scale(0);
        transition: transform .3s ease;
        z-index: 2;
      }

      // after is needed instead of border on the parent element, because it fixes a bug with one transparent pixel on the border
      &::after {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 2px solid $unselected-light;
        border-radius: 4px;
        transition: border-color .3s ease;
        z-index: 3;
      }
  
      .checkmark {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 15px;
        color: $transparent;
        transition: color .3s ease;
        transform: translate(-50%, -50%);
        z-index: 3;
      }
    }

    .title {
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      color: $text-color-standard;
      vertical-align: middle;
    }
  }
}

.p-field-checkbox,
.p-treenode-content {
  padding: 1px 0;
  display: inline-block;

  &:hover {

    .p-checkbox:not(.p-checkbox-disabled) {

      .p-checkbox-box {

        &::after {
          border-color: $secondary-hover;
        }
      }
    }
  }

  &.p-treenode-content {
    padding: 0;
    display: flex;

    .p-checkbox {
      
      .p-indeterminate {

        &.p-highlight {

          .p-icon-wrapper {
            color: $transparent !important;
          }
        }

        .p-icon-wrapper {
          color: $transparent !important;

          svg {
            display: none;
          }
        }
      }
    }
  }

  p-checkbox {
    vertical-align: middle;
  }

  .p-checkbox {
    width: 24px;
    height: 24px;
    // TODO - will not be needed once old p-checkbox styles below are removed
    bottom: auto;
    border: none;

    // start disabled
    &.p-checkbox-disabled {

      &:hover {

        .p-checkbox-box {

          &::before {
            border-color: $disabled;
          }
        }
      }

      .p-checkbox-box {
        border-color: $disabled;

        &.p-highlight {
          box-shadow: none;

          &::before {
            color: $content-disabled;
            background-color: $disabled;
            border-color: $disabled;
          }

          .p-checkbox-icon {
            color: $unselected-light;
          }
        }

        &::before {
          border-color: $disabled;
        }
      }
    }
    // end disabled

    &:hover {
      border: none;

      .p-checkbox-box {

        &::before {
          border-color: $secondary-hover;
        }
      }
    }

    .p-checkbox-box {
      width: 24px;
      height: 24px;
      position: relative;
      background-color: $transparent;
      border: none;
      border-radius: 4px;
      overflow: hidden;
      transition: box-shadow .3s ease;

      // focused
      &.p-focus {
        box-shadow: none;

        &::before {
          border-color: $secondary-hover;
        }
      }

      // active
      &.p-highlight {
        box-shadow: 0 3px 10px 0 rgba(0,0,0,0.2), 0 5px 40px 0 rgba(74,116,255,0.3);

        &:hover {
          border-color: $primary;
          background-color: $transparent;
        }

        &::before {
          color: $white;
          background-color: $secondary-hover;
          border-color: $secondary-hover;
        }
        
        .p-checkbox-icon {
          color: $temp-gray;
          width: 13px;
          height: 13px;
          position: relative;
          top: 0;
          stroke: #ffffff;
          stroke-width: 1px;
        }
      }
  
      &::before {
        content: "check" / "";
        display: inline-block;
        width: 24px;
        height: 24px;
        padding: 2px 0 0 1px;
        position: absolute;
        top: 0;
        left: 0;
        font-family: "Material Icons";
        font-size: 16px;
        font-feature-settings: "liga";
        line-height: 1;
        color: rgba(255, 255, 255, 0);
        background-color: rgba(255, 255, 255, 0);
        border: 2px solid #AFB3C1;
        border-radius: 4px;
        overflow: hidden;
        text-align: center;
        transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
        z-index: 2;
      }

      .p-icon-wrapper {
        display: none;
      }
    }
  }

  .p-checkbox-label {
    padding-left: 15px;
    margin-bottom: 0;
    margin-left: 0;
    font-size: 14px;
    font-weight: 700;
    color: $content-primary;
    cursor: pointer;
    vertical-align: middle;
    user-select: none;

    &.disabled {
      color: $unselected-disabled;
      cursor: not-allowed;
    }
  }
}

// TODO - remove those once the styles from above are approved
// TODO - verify the checkbox in the table filters
// seems like this is old, should be removed?
// not sure what it impacts
.p-checkbox {
  width: 20px;
  border: 2px solid $unselected-light;
  height: 20px;
  bottom: 1px;
  border-radius: 4px;

  &:hover {
    border: 2px solid $primary;
  }

  &.disabled {
    border-color: $disabled;
    background-color: none;
    color: $unselected-light;
  }
}

// TODO - 
// this is currently scoped to formio and is used for consent form checkbox - it can possibly break other checkboxes, but we'll get to it once we work on the kitchen sink checkboxes
.form-check-label {
  cursor: pointer;

  &:hover {

    .checkbox {
      border-color: $secondary-hover;
    }
  }

  .form-check-input {
    // display: none;

    &:checked + span .checkbox {
      border-color: $secondary-hover;
      box-shadow: 0 3px 10px 0 rgba(0,0,0,0.2), 0 5px 40px 0 rgba(74,116,255,0.3);

      &::before {
        transform: scale(1);
      }

      .check {
        color: $temp-gray;
      }
    }
  }

  .checkbox {
    display: inline-block;
    position: relative;
    width: 22px;
    height: 22px;
    margin-right: 15px;
    border: 2px solid $unselected-light;
    background-color: $transparent;
    border-radius: 4px;
    overflow: hidden;
    transition: border-color .3s ease, box-shadow .3s ease;
    vertical-align: middle;
    z-index: 1;

    &::before {
      display: inline-block;
      content: '';
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      background-image: linear-gradient(to bottom, $temp-blue01, $temp-blue02);
      border-radius: 50%;
      transform: scale(0);
      transition: transform .3s ease;
      z-index: 2;
    }

    .check {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      color: $transparent;
      transition: color .3s ease;
      transform: translate(-50%, -50%);
      z-index: 3;
    }
  }
}