@import "src/scss/vars.scss";
// prime toggle
.p-inputswitch {
  height: 24px;
  width: 44px;
  vertical-align: middle;
  outline-offset: 2px;
  border-radius: 30px;

  &.p-focus {
    outline: $outline-focused;
  }
}

.p-inputswitch .p-inputswitch-slider {
  background: $secondary-disabled;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s,
    box-shadow 0.3s;
  border-radius: 30px;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: $white;
  width: 18px;
  height: 18px;
  left: 3px;
  margin-top: -9px;
  border-radius: 50%;
  transition-duration: 0.3s;
  box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.25);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(19px);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: rgba(175, 179, 193, 0.6);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: $secondary-hover;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: $white;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover {
    .p-inputswitch-slider {
    background: $secondary-hover;
  }
}

.p-disabled,
.p-component:disabled {
  opacity: 1;
}

// Custom toggle
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  vertical-align: middle;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $secondary-disabled;
  transition: color 0.3s ease;

  &:hover {
    background-color: rgba(175, 179, 193, 0.6);
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: $white;
  box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.25);
  transition: 0.3s ease;
}

input:checked + .slider {
  background-color: $secondary-hover;
}

.disabled {

  .slider {
    cursor: not-allowed;
    background-color: $disabled;
  }
}

input:checked + .slider:before {
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-text {
  font-size: 14px;
  font-family: $font-family-primary;
  font-weight: bold;
  line-height: 17px;
  padding-left: 14px;
  color: $text-color-standard;
  vertical-align: middle;

  &.p-toggle {
    color: $text-color-standard;
    padding-left: 15px;
  }

  &.toggle-text-disabled {
    color: $unselected-light;
  }
}

