@import "src/scss/vars.scss";

.link {
  font-family: $font-family-primary;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  color: $secondary-hover;
  transition: color .3s ease;

  &:hover {
    text-shadow: 0 0 0 1px $black;
  }
  &:active {
    color: $secondary-pressed;
  }
}

.link-disabled{
    pointer-events: none;
    cursor: default;
    color: $unselected-light;
}