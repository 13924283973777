@import "src/scss/vars.scss";

.button-icon {
  height: 48px;
  width: 48px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1px;
  font-family: $font-family-primary;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem transparent;
  white-space: nowrap;
  text-align: center;
  display: block;
}

.delete-w-stroke {
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  color: $unselected-light;

  &:enabled {
    background-color: $white;
    color: $unselected-light;
    border: 1px solid $secondary;

    .material-icons {
      font-size: 20px;
    }

    &:hover {
      color: $urgent;
      box-shadow: none;
      border-color: rgba(255, 105, 105, 0.3);
      background: $white;
    }

    &:active {
      background: $white;
      color: $urgent-light;
      border: 1px solid $urgent-light;
    }

    &:focus {
      color: $urgent-light;
      background-color: $white;
      box-shadow: none;
      border: 1px solid $urgent-light;
    }

    &:focus-visible {
      outline: $outline-focused;
    }

    &:visited {
      color: $urgent-light;
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: $unselected-light;
    background-color: $white;
    border: 1px solid $secondary;
    opacity: 0.5 !important;

    .material-icons {
      font-size: 20px;
    }
  }

  .p-button-label {
    display: none;
  }
}

.icon-w-stroke {
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  .p-button-label {
    display: none;
  }

  &:enabled {
    background-color: $white;
    border: 1px solid $border-info-subtle;
    color: $secondary-hover;

    .material-icons {
      font-size: 24px;
    }

    &:hover {
      background: $white;
      border-color: $secondary-hover;
      color: $secondary-hover;
    }

    &:active {
      border-color: $secondary-pressed;
      background: $white;
      color: $secondary-hover;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: $outline-focused;
    }

    :not(:focus) {
      background-color: $white;
    }
  }

  &:disabled {
    background-color: $white;
    border: 1px solid $secondary-disabled;
    color: $unselected-light;
    background-image: none;
    cursor: not-allowed;
    border: 1px solid $disabled;
  }

  &--medium {
    width: 42px;
    height: 42px;
  }

  &--smaller {
    width: 36px;
    height: 36px;

    &:enabled {

      .icon-small {
        font-size: 20px;
      }
    }
  }
}

.p-disabled:disabled {
  opacity: 1;
  cursor: not-allowed;
}

.button-icon-round {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-family: $font-family-primary;
  font-weight: bold;
  box-shadow: 0 0 0 0.2rem transparent;
  white-space: nowrap;
  padding: 0;
  text-align: center;
  display: block;

  .material-icons {
    font-size: 16px;
    margin-top: 2px;
  }

  .p-button-label {
    display: none;
  }

  &.inline-block {
    display: inline-block;
  }
}

.icon-w-fill {
  transition: background-color 0.3s ease;

  &:enabled {
    background: $scroll;
    color: $secondary-hover;
    border: none;

    &:hover {
      background: $secondary-disabled;
      color: $secondary-hover;
      border: none;
      opacity: 0.9;
    }

    &:active {
      background: $secondary-disabled;
      opacity: 1;
      border: none;
    }

    &:focus {
      box-shadow: none;
    }

    &:focus-visible {
      outline: $outline-focused;
    }
  }

  &:disabled {
    background: $scroll;
    color: $unselected-light;
    cursor: not-allowed;
    border: none;
  }

  &.icon-w-fill--transparent {

    &:enabled {
      background-color: $transparent;
      color: $content-link;
      transition: color .3s ease;

      &:hover {
        background-color: $transparent;
        color: darken($content-link, 10%);
      }

      &:active {
        background-color: $transparent;
        color: darken($content-link, 10%);
      }

      &:focus-visible {
        outline: $outline-focused;
      }
    }
  }

  &--smaller {
    height: 36px;
    width: 36px;
    padding-top: 2px;
    font-size: 14px;

    .material-icons {
      font-size: 18px;
      padding-top: 2px;
    }
  }
}

.icon-w-fill.p-button {

  .material-icons {
    margin-top: 2px;
  }
}

.icon-default {
  transition: background-color 0.3s ease;

  .material-icons {
    font-size: 27px;
    margin: -2px 0 0 -2px;
  }

  &.button-link {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    color: $unselected-light;
    border: none;

    &:hover {
      background: $white;
      opacity: 0.9;
      color: $secondary-hover;
      border: none;
    }

    &:focus {
      box-shadow: none;
    }

    &:focus-visible {
      outline: $outline-focused;
    }
  }

  &:enabled {
    background: $white;
    color: $unselected-light;
    border: none;

    &:hover {
      background: $white;
      opacity: 0.9;
      color: $secondary-hover;
      border: none;
    }

    &:active {
      background: $white;
      opacity: 1;
      color: $secondary-hover;
      border: none;
    }

    &:focus {
      box-shadow: none;
    }

    &:focus-visible {
      outline: $outline-focused;
    }
  }

  &:disabled {
    background: $white;
    color: $unselected-light;
    cursor: not-allowed;
    opacity: 0.3;
    border: none;
  }
}

.icon-default.p-button {

  .material-icons {
    font-size: 28px;
  }
}
