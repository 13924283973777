html {
  height: auto;
  font-family: $font-family-base !important;
  font-size: 62.5%;
  cursor: default;
  overflow: visible;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  text-rendering: optimizeLegibility;

  &.modal-opened {
    // overflow: hidden;

    body {
      // TODO - perhaps this padding is not needed
      // padding-right: 17px;
      overflow: hidden;

      .combined-header {

        &.on-top {
          left: calc(50% - 6px);
        }
      }
    }
  }
}

html.loading-API {

  .main {
  
    &.loginPageVisible {
  
      .page-loader {
        display: none;
      }
    }
  }

  .page-loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    user-select: none;
    z-index: 20;

    &.stopLoader {

      .loader-overlay {
        backdrop-filter: none;
      }
    }

    .loader-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      backdrop-filter: blur(8px);
      z-index: 1;
    }

    .loader-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      svg {
        width: 64px;
        height: 64px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      // &::before {
      //   display: inline-block;
      //   content: '';
      //   width: 48px;
      //   height: 48px;
      //   border-radius: 50%;
      //   border: 4px solid #D7E0FF;
      //   border-top-color: #4D71FE;
      //   animation: spin 1s infinite linear;
      //   transform: rotate(45deg);
      // }
    }
  }

  .page-wrapper {
    // position: relative;
    // filter: blur(8px);

    &.stopLoader {
      filter: none;
    }
  }

  .fullscreen {
    filter: blur(8px);
  }

  .p-dialog {
    filter: blur(8px);
  }

  .p-column-filter-overlay {
    filter: blur(8px);
    pointer-events: none;
    user-select: none;
  }
}

@keyframes spin {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(404deg);
  }
}

body {
  height: auto;
  position: relative;
  font-family: $font-family-primary;
  font-size: 14px;
  font-weight: 400;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
  overflow-x: hidden;

  // primeng modal shown
  &.p-overflow-hidden {
    // fixes issue with jumping content whenever a modal is opened
    padding-right: 13px;

    .combined-header {
      &.on-top {
        left: calc(50% - 6px);
      }
    }

    .reg-header {
      &.on-top {
        left: calc(50% - 6px);
      }
    }
  }

  // weird fix for class .loader
  .loader {
    display: block;
    width: auto;
    height: auto;
    margin-left: 0;
    margin-top: 0;
    position: static;
    left: auto;
    top: auto;
    border: none;
    border-radius: 0;
    animation: none;
    z-index: 1;
  }
}

// Enable placeholder colors
::-webkit-input-placeholder {
  color: $placeholder-color;
}
:-moz-placeholder {
  color: $placeholder-color;
}
:-ms-input-placeholder {
  color: $placeholder-color;
}

a {
  text-decoration: none;
  color: $links;
  background-color: transparent;

  &:hover {
    color: $links-hover;
  }
}

// perhaps there is a better place for this?
label {
  margin-bottom: 0;
}

.basic-modal {
  .modal-content {
    padding: 32px 40px;
    border-radius: 16px;
  }
  
  .modal-lg {
    max-width: 400px;
  }

  .modal-sm {
    max-width: 400px;
  }
}

// used for general setup and forums currently
.component-header {
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;

  &.on-top {
    position: fixed;
    width: 1192px;
    padding: 30px 0;
    left: 50%;
    background-color: $white;
    transform: translateX(-50%);
    z-index: 22;

    &.on-top-with-header {
      top: 14px;
    }

    &::before {
      display: inline-block;
      content: '';
      width: 100vw;
      position: absolute;
      left: 50%;
      bottom: 0;
      border-bottom: 1px solid $lighter-gray;
      transform: translateX(-50%);
      // background-color: $white;
      // box-shadow: -15px -3px 9px 7px white;
      z-index: -1;
    }

    &::after {
      display: inline-block;
      content: '';
      width: 100vw;
      position: absolute;
      top: -200px;
      left: -25px;
      bottom: 0;
      background-color: $white;
      z-index: -2;
    }

    @media screen and (max-width: 1440px) {
      width: 100%;
      max-width: calc(100% - 115px);
    }
  }
}

// fix for quill font family
.p-editor-content .ql-editor {
  font-family: $font-family-primary;
}

// fix for quill add link popup input inside forum post modal 
.forum-post-dialog {

  .editor.ql-container {

    .ql-tooltip {
      left: 50% !important;
      transform: translate(-50%, 10px);
    }
  }
}

// fix for quill add link popup input inside widget edit dialog modal
.widget-edit-dialog {

  .editor.ql-container {

    .ql-tooltip {
      left: 50% !important;
      transform: translate(-50%, -50%);
      top: 50% !important;
      margin-top: 0 !important;
    }
  }
}