@import "src/scss/vars.scss";

.notification-medium {
    font-family: $font-family-primary;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    border: none;
    height: 82px;
    display: inline-flex;
    cursor: auto;
    box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
      0 5px 40px 0 rgba(239, 239, 239, 0.3);
      .notification-medium-text{
        padding: 30px  143px 30px 30px;
        font-size: 18px;
        color: $text-color-standard;
        white-space: nowrap;
      }
      .notification-medium-button{
        padding: 17px  17px 17px 0px;
        font-size: 16px;
        color: $text-color-standard;
      }
}