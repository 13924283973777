// =================
// Common Properties
// =================
// Misc
$speed: 200ms !default; // Transition duration
$transition: cubic-bezier(0.55, 0, 0.1, 1) !default; // Animation Style. Default is Material's curve.
$outline-focused: 2px solid #3D3F46;

// Colors
$transparent: rgba(255, 255, 255, 0) !default;
$white: #ffffff !default;
$light-white:#fcfcfc !default;
$black: #000000 !default;
$blue: #4D71FE;
$blue-darker: #4565e4;
$blue-lightest: #94AAFE;
$red: #F34A4A;
$lighter-red: #FEF6F6;
$yellow: #FFB446;
$light-yellow:#FFCB4A;
$light-green: #85D828;
$lighter-green: #F5FBF6;
$lightest-yellow: #FFF9EC;
$green: #55BD70;
$green-hover: #D4EDDB;
$red-hover: #FFE5E5;
$yellow-hover: #FFF2D2;
$gray: #afb3c1;
$light-gray: #D0D3DB;
$lighter-gray: #E3E4E9;
$lightest-gray: #F2F3F5;

// icon colors
$icon-blue: #4A74FF !default;
$icon-gray: #848998 !default;
$icon-gray-disabled: #b8bcc7 !default;

// text
$text-color-standard: #3D3F46 !default;
$text-color: $text-color-standard !default; //** Global text color
$light-text: #afb3c1;

$content-primary: #1F2022 !default;
$content-tertiary: #5E616D !default;
$content-contrast: #727685 !default;
$content-disabled: #AFB3C1 !default;
$content-link: #305FFF !default;

$border-primary: #305FFF !default;
$border-secondary: #D0D3DB !default;
$border-tertiary: #E3E4E9 !default;
$border-info-subtle: #ACBFFF !default;
$border-disabled: #F2F3F5 !default;
$border-positive-primary: #19AE3A !default;

// additional
$links: #4D71FE !default;
$links-hover: #4565E4 !default;
$input-glow-color: #4D71FE !default;
$gray-background:#f2f2f2 !default;
$gray-background-2: #f7f7f7 !default;

//kitchen colors
$primary: #4A74FF;
$primary-hover: #5678FF;
$secondary: #D7E0FF;
$secondary-hover: #4D71FE;
$secondary-pressed: #4565E4;
$secondary-disabled: #E3E4E9;
$secondary-hover-animate: #4D71FE 0.7;

$grey-text: #848998;
$unselected-light: #AFB3C1;
$unselected-disabled: #D0D3DB;
$disabled: #E3E4E9;
$scroll: #F2F3F5;
$scrollbar-dark: #d4d6dc;
$background: #EEF1FF;

$positive-midrange: #47BE61 !default;

$background-neutral-subtle: #F2F3F5 !default;
$background-info-primary: #305FFF !default;
$background-info-subtle: #EAEFFF !default;
$background-info-subtle-hover: #D6DFFF !default;
$background-neutral-off-color: #F9F9FA !default;
$background-neutral-low-contrast: #E3E4E9 !default;
$background-neutral-midrange: #AFB3C1 !default;
$background-neutral-midrange-low: #D0D3DB !default;
$background-neutral-midrange-high: #848998 !default;
$background-positive-subtle: #D1EFD8 !default;
$background-positive-high-contrast: #E8F7EB !default;

$urgent: #F34A4A;
$urgent-border: #FFA5A5;
$urgent-hover: #DB4242;
$urgent-pressed: #F45B5B;
$urgent-light: #FF6969;
$no-error: #34B354;
$green-solid: #35B657;
$green-border: #99D7A9;
$green-hover: #2FA44E;
$green-pressed: #48BD67;

// TODO - update all temp variables to real ones
$temp-gray: #F3F4FA;
$temp-blue01: #4A74FF; //gradient-blue01?
$temp-blue02: #4E6FFE; // gradient-blue02?
$temp-blue03: #4271ff;


$body-bg: $background-neutral-subtle !default; //** Background color for `<body>`
$placeholder-color: $text-color !default; //** Color for placeholder=""
$selection-text: $text-color !default; // Color of selected text
$selection-background: $body-bg !default; // Background of selected text


// Fonts
$font-size-base : 1.6rem !default;
$line-height-base: 1.5;
$font-family-primary : "Inter", Helvetica, Arial, sans-serif;
$font-family-secondary : $font-family-primary;
$font-family-sans-serif : $font-family-primary;
$font-family-serif : $font-family-secondary;
$font-family-base : $font-family-primary;