@import "src/scss/vars.scss";


// primeng/multiselect
.p-multiselect {
  height: 48px;
  min-width: 300px;
  width: 100%;
  border: 1px solid #e3e4e9;
  border-radius: 8px;
  padding: 8px 15px 0;

  &.p-multiselect-open {
    box-shadow: 0 5px 10px 0 rgb(168 168 168 / 20%), 0 10px 40px 0 rgb(239 239 239 / 30%) !important;
  }

  &:not(.p-disabled).p-focus {
    outline: $outline-focused;
  }

  &:first-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  &:not(.p-state-disabled):hover {
    border: 1px solid #e3e4e9;
  }

  li {
    box-shadow: none !important;
  }

  .ng-trigger-overlayAnimation {
    top: 59px !important;
    border-radius: 8px;
    border: none;
  }

  // TODO See if we need var for this too
  .p-multiselect-header {
    display: none;
  }

  .p-checkbox {
    border: none;

    .p-checkbox-box {
      border: 2px solid $unselected-light;
      border-radius: 4px;

      &:hover {
        border-color: $secondary-pressed;
      }

      &.p-highlight {
        background: $secondary-hover;
        border-color: $secondary-hover;
        box-shadow: 0 0px 10px 0 rgb(0 0 0 / 20%), 0 0px 7px 0 rgb(74 116 255 / 30%);

        &:hover {
          background: $secondary-hover;
          border-color: $secondary-hover;
        }

        &::before {
          transform: scale(1);
        }

        &::after {
          border-color: $secondary-hover;
        }

        .p-checkbox-icon {
          width: 10px;
          height: 10px;
          position: relative;
          top: 1px;
          stroke: $white;
          stroke-width: 1px;
        }
      }
    }
  }

  .p-state-highlight {
    outline: none;
    background-color: #ffffff !important;
  }

  .p-multiselect-panel {

    .p-multiselect-items-wrapper {
      border-radius: 8px;

      .p-multiselect-items {
        padding: 20px;

        .p-multiselect-item {
          align-items: flex-start;
          background-color: $transparent;
          padding: 6px 4px;
          
          &.p-disabled {

            .p-checkbox {

              .p-checkbox-box {
                border-color: $disabled;
              }
            }

            .ng-star-inserted {
              color: $secondary-disabled;
            }
          }

          &:not(.p-highlight):not(.p-disabled):hover {
            color: $text-color-standard;
            background: $white;

            .p-checkbox {

              .p-checkbox-box {
                border-color: $secondary-pressed;
              }
            }
          }

          &.p-highlight {
            color: $text-color-standard;
            background: $white;
          }

          &.p-focus {
            outline: $outline-focused;
            border-radius: 2px;
          }

          .p-checkbox {
            margin-left: 3px;
            margin-right: 3px;
          }

          .p-multiselect-representative-option,
          span.ng-star-inserted {
            margin-left: 10px;
            color: $text-color-standard;
            font-family: $font-family-primary;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 17px;
            white-space: break-spaces;
          }
        }
      }
    }
  }

  .p-multiselect-label {
    letter-spacing: 0;
    font-family: $font-family-primary;
    font-size: 14px;
    margin-top: 10px;
    font-weight: bold;
    padding: 5px 0 0;
    line-height: 17px;
    color: $text-color-standard;
  }

  .p-multiselect-trigger-icon {
    font-size: 18px;
    padding-bottom: 2px;
    color: $secondary-hover;
    &::before {
      content: "\e313";
      font-family: "Material Icons";
    }
  }
}

