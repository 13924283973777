@import 'vars.scss';

// container styles for forms (with header - these are the edit screens)
.formio-custom-wrapper {
  max-width: 1192px;
  width: 100%;
  margin: 0 auto;
}

// hides the actual formio loader wrapper
.formio-loader-wrapper {
  display: none !important;
}

// this hides the wrapper of formio loader wrapper ://
.formio-custom-wrapper {

  & > formio {

    & > div[role="form"] {

      & > div:first-child:not(formio-alerts) {
        height: auto !important;
      }
    }
  }
}

// actual form styles
.formio-custom-wrapper,
.formbuilder,
app-form-view {
  margin-top: 67px;

  @media print {
    margin-top: 20px;
  }

  div[role="form"] {
    padding: 0 30px 0 60px;
  }

  h2 {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 43px;
    color: $content-primary;
  }

  h3 {
    margin-bottom:16px;
    font-size: 48px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: -1px;
    color: $content-primary;
  }

  h4 {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: $content-primary;
  }

  p {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 32px;
    color: $content-primary;

    a {
      color: $blue;
      transition: color .3s ease;
      border-radius: 2px;

      &:hover {
        color: darken($blue, 10%);
      }

      &:focus-visible {
        outline: $outline-focused;
      }
    }
  }

  ul,
  ol {
    margin-bottom: 24px;
    padding-left: 30px;
    font-size: 20px;
    line-height: 32px;
    color: $content-primary;

    li {
      padding-left: 6px;

      a {
        color: $blue;
        transition: color .3s ease;
        border-radius: 2px;

        &:hover {
            color: darken($blue, 10%);
        }

        &:focus-visible {
          outline: $outline-focused;
        }
      }
    }
  }

  .card {
    background-color: $transparent;
    border: none !important;

    .card-body {
      padding: 0;
    }
  }

  .form-group {
    margin-bottom: 5px;
  }

  // panel
  .formio-component-panel {
    padding: 20px 0 5px 0;
    margin: 0 0 11px 0;
    break-inside: avoid;

    &.question-marked-valid {

      .formio-component-content {

        h4 {
          position: relative;

          &::after {
            display: inline-block;
            content: 'done';
            width: 18px;
            height: 18px;
            margin-left: 10px;
            font-family: 'Material Icons';
            font-size: 12px;
            font-weight: 100;
            line-height: 18px;
            color: $white;
            background-color: $green-solid;
            border-radius: 50%;
            text-align: center;
            print-color-adjust: exact;
          }
        }
      }
    }

    &.question-marked-invalid {
      position: relative;

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        right: -30px;
        bottom: 0;
        left: -30px;
        background-color: rgba($urgent, .05);
        border-radius: 12px;
      }

      .formio-component-content {

        h4 {
          position: relative;

          &::after {
            content: "priority_high";
            display: inline-block;
            width: 18px;
            height: 18px;
            padding-top: 2px;
            margin-left: 10px;
            font-family: "Material Icons";
            font-size: 12px;
            font-weight: 100;
            letter-spacing: 0px;
            line-height: 1;
            color: $white;
            background-color: $urgent;
            border: 1px solid $urgent;
            border-radius: 50%;
            text-align: center;
          }
        }
      }
    }
  }

  // input, textarea, number, datetime, url, email fields, phone number, currency
  .formio-component-textfield,
  .formio-component-textarea,
  .formio-component-number,
  .formio-component-datetime,
  .formio-component-url,
  .formio-component-email,
  .formio-component-phoneNumber,
  .formio-component-currency {
    position: relative;

    // description field
    & .form-text {
      margin-top: 2px;
      // height: 21px;
      font-size: 14px;
    }

    &.has-error {

      & .form-text {
        // padding-bottom: 22px;
      }
    }

    &.formio-error-wrapper {
      // padding: 15px;

      label {

        &.field-required {

          &.control-label--hidden {
            top: 24px;
            right: 25px;
          }
        }
      }

      .formio-errors {
        bottom: 10px;
        left: 15px;
      }
    }

    .field-label {
      padding-top: 10px;
    }

    label {

      &.col-form-label {
        padding: 0;
        font-size: 20px;
      }

      &.field-required {

        &.control-label--hidden {
          top: 14px;
          right: 10px;
        }

        &::after {
          font-family: $font-family-primary;
          font-size: 16px;
          color: $urgent;
        }
      }
    }

    div[ref="element"] {
      min-height: 71px;

      input.form-control,
      textarea.form-control {
        display: block;
        height: 48px;
        // width: 100%;
        padding: 12px 20px 11px 14px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: $content-primary;
        background-color: $white;
        border: 1px solid $secondary-disabled;
        border-radius: 8px;
        transition: box-shadow .3s ease;
        z-index: 1;

        &::placeholder {
          color: $light-text;
        }
  
        &:focus {
          box-shadow: 0 5px 10px 0 rgba(168,168,168,0.2),
                      0 10px 40px 0 rgba(239,239,239,0.3);
        }

        &:focus-visible {
          outline: $outline-focused;
        }

        &.is-invalid {
          border-color: $urgent;
          opacity: 0.9;
          padding-right: 14px;
          background-image: none;

          & ~ .input-group-append {

            .input-group-text {
              border-color: $urgent;
            }
          }
        }

        &:disabled {
          background-color: $lightest-gray;
          border-color: $disabled;
        }
      }

      textarea.form-control {
        height: auto;
      }

      // disabled text area
      .card.card-body {
        background-color: $transparent !important;

        .formio-editor-read-only-content {
          display: block;
          min-height: 48px;
          // width: 100%;
          padding: 15px 14px 11px;
          font: inherit;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color: $content-primary;
          background-color: $lightest-gray;
          border: 1px solid $disabled;
          border-radius: 8px;
        }
      }

      // cases with icon at the start and/or end of the input
      .input-group {

        // icon at the start
        .input-group-prepend {
          z-index: 2;

          & + input.form-control {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          .input-group-text {
            background-color: #fff;
            border: 1px solid #E3E4E9;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            transition: box-shadow .3s ease;

            .fa {
              color: $icon-blue;
            }
          }

          &:has(+ input.form-control:focus) {
            .input-group-text {
              box-shadow: 0 5px 10px 0 rgba(168,168,168,0.2),
                          0 10px 40px 0 rgba(239,239,239,0.3);
            }
          }
        }

        input.form-control {

          &:has(+ .input-group-append) {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          &:focus {

            & ~ .input-group-append {

              .input-group-text {
                box-shadow: 0 5px 10px 0 rgba(168,168,168,0.2),
                            0 10px 40px 0 rgba(239,239,239,0.3);
              }
            }
          }
        }

        // icon at the end
        .input-group-append {
          z-index: 1;

          .input-group-text {
            background-color: $white;
            border: 1px solid $secondary-disabled;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            transition: box-shadow .3s ease;

            .fa {
              color: $icon-blue;
            }
          }
        }
      }

      // .form-text {

      //   // word counter
      //   &.text-right {
      //     margin-top: 0;
      //     font-size: 12px;
      //   }
      // }
    }

    // .formio-errors {
      // position: absolute;
      // bottom: 0;
      // left: 0;
    // }
  }

  .formio-component-day {

    label {

      &.field-required {

        // default asterisk when label is shown
        &::after {
          content: ' *' / '';
        }

        // when the field is required and it has label - remove asterisk from div
        & + div {
          &::before {
            display: none;
          }
        }
      }
    }

    // when the field is required and there is no label - add asterisk
    div:has(> input[aria-required="true"]) {
      position: relative;

      &::before {
        content: '*' / '';
        position: absolute;
        top: 14px;
        right: 10px;
        font-family: $font-family-primary;
        font-size: 16px;
        color: $urgent;
        pointer-events: none;
        z-index: 2;
      }
    }

    // when the field is required and there is no label - add asterisk
    div:has(> select[aria-required="true"]) {
      position: relative;

      &::before {
        content: '*' / '';
        position: absolute;
        top: 14px;
        right: 22px;
        font-family: $font-family-primary;
        font-size: 16px;
        color: $urgent;
        pointer-events: none;
        z-index: 2;
      }
    }

    input.form-control,
    select.form-control {
      display: block;
      height: 48px;
      // width: 100%;
      margin-bottom: 21px;
      padding: 12px 20px 11px 14px;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: $content-primary;
      background-color: $white;
      border: 1px solid $secondary-disabled;
      border-radius: 8px;
      transition: box-shadow .3s ease;
      z-index: 1;

      &::placeholder {
        color: $light-text;
      }

      &:focus {
        box-shadow: 0 5px 10px 0 rgba(168,168,168,0.2),
                    0 10px 40px 0 rgba(239,239,239,0.3);
      }

      &:focus-visible {
        outline: $outline-focused;
      }

      &.is-invalid {
        border-color: $urgent;
        opacity: 0.9;
        padding-right: 14px;
        background-image: none;

        & ~ .input-group-append {

          .input-group-text {
            border-color: $urgent;
          }
        }
      }

      &:disabled {
        background-color: $lightest-gray;
        border-color: $disabled;
      }
    }
  }

  // specifics
  .formio-component-number {

    .formio-errors {
      position: static;
    }
  }

  // tags
  .formio-component-tags {

    .choices {

      &.is-disabled {

        .choices__inner {
          cursor: default;
          background-color: $lightest-gray;
          border-color: $disabled;

          .choices__list.choices__list--multiple {

            .choices__item {
              color: $content-primary;
              background-color: $light-gray;
            }
          }
        }
      }

      .choices__inner {
        height: 52px;
        padding: 12px 12px 11px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: $content-primary;
        background-color: $white;
        border: 1px solid $secondary-disabled;
        border-radius: 8px;
        display: block;

        .choices__list.choices__list--multiple {
          display: inline-block;
    
          .choices__item {
            margin: 0 8px 0 0;
            padding: 3px 8px 4px 7px;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            cursor: default;
            background-color: $blue;
            border: none;
            border-radius: 3px;
    
            .choices__button {
              width: 16px;
              height: 16px;
              padding: 0;
              background-size: 10px;
              border-left: none;
            }
          }
        }

        .choices__input {
          background-color: $transparent;
        }
      }

      .choices__list.choices__list--dropdown {
        border: 1px solid $secondary-disabled;
        border-radius: 8px;
      }
    }
  }

  // dropdown
  .formio-component-select {
    position: relative;

    &.has-error.has-message {
      padding-bottom: 22px;
    }

    label {

      &.col-form-label {
        padding: 0;
        font-size: 16px;
      }

      &.field-required {

        &.control-label--hidden {
          top: 14px;
          right: 10px;
        }

        &::after {
          font-family: $font-family-primary;
          font-size: 16px;
          color: $urgent;
        }
      }
    }

    .choices.formio-choices {

      &.is-disabled {

        &::after {
          display: inline-block !important;
          color: $gray;
        }
        
        .form-control.dropdown {
          background-color: $lightest-gray;
          border-color: $disabled;
          cursor: default;

          .choices__list {

            .choices__item {
  
              // remove button (clear selection)
              .choices__button {
                display: none;
              }
            }
          }
        }
      }

      // arrow
      &::after {
        content: "\e316";
        height: 20px;
        width: 20px;
        margin-top: -2.5px;
        position: absolute;
        right: 18px;
        top: 17px;
        font-family: "Material Icons";
        font-size: 20px;
        line-height: 20px;
        color: $blue;
        border: none;
        transform: rotate(180deg);
        pointer-events: none;
        z-index: 1;
      }

      // opened
      &.is-open {

        &::after {
          transform: rotate(0deg);
        }

        .form-control.dropdown {
          box-shadow: 0 5px 10px 0 rgba(168,168,168,0.2),
                      0 10px 40px 0 rgba(239,239,239,0.3);

          &:focus {
            box-shadow: 0 5px 10px 0 rgba(168,168,168,0.2),
                        0 10px 40px 0 rgba(239,239,239,0.3);
          }
        }
      }

      // flipped
      &.is-flipped {

        .choices__list--dropdown {
          top: auto;
          bottom: calc(100% + 5px);
        }
      }

      .form-control.dropdown {
        height: 48px;
        padding: 12px 14px 11px;
        font-size: 14px;
        line-height: 17px;
        color: $content-primary;
        background-color: $white;
        border: 1px solid $lighter-gray;
        border-radius: 8px;
        transition: box-shadow 0.3s ease;
        z-index: 1;
        display: block;

        &:focus-visible {
          outline: $outline-focused;
        }

        &:focus {
          box-shadow: none;
        }

        &.is-invalid {
          border-color: $urgent;
          opacity: 0.9;
          padding-right: 14px;
          background-image: none;
        }

        .choices__list {

          .choices__item {
            font-family: $font-family-primary;
            font-size: 14px;
            line-height: 22px;

            &.choices__placeholder {
              color: $light-text;
              opacity: 1;
            }

            // fix for remove button hidden on not selected state
            & > span + .choices__button {
              display: block;

              &:focus-visible {
                outline: $outline-focused;
                box-shadow: none;
              }
            }

            // remove button (clear selection)
            .choices__button {
              margin-right: 42px;
              background-size: 10px;
              display: none;
            }
          }
        }
      }

      .choices__list--dropdown {
        top: calc(100% + 11px);
        background-color: $white;
        box-shadow: 0 5px 10px 0 rgb(168 168 168 / 20%), 0 10px 40px 0 rgb(239 239 239 / 30%);
        border: none;
        border-radius: 8px;

        .choices__list {
          padding: 12px 0;

          .choices__item {
            padding: 8px 18px 9px 20px;
            font-family: $font-family-primary;
            font-size: 16px;
            line-height: 19px;
            color: $grey-text;
            background-color: transparent;
            transition: background-color .3s ease;

            &:hover {
              color: $grey-text;
              background-color: #f3f3f3;
            }

            &.is-selected {
              background-color: $lighter-gray;
            }
          }
        }
      }
    }

    .formio-errors {
      position: static;
    }
  }

  // radio
  .formio-component-radio {

    .col-form-label {
      margin-bottom: 10px;
      padding-left: 0;
      font-size: 16px;
      line-height: 28px;
      color: $content-primary;
    }

    &.required {

      // radio group
      div.form-radio.radio {

        // wrapper
        .radio.form-check {

          .form-check-label {

            &::before {
              display: inline-block;
              content: '* ' / '';
              position: absolute;
              top: 4px;
              left: -15px;
              right: auto;
            }
          }
        }
      }
    }

    // radio group
    div.form-radio.radio {
      // padding-left: 24px;

      // wrapper
      .radio.form-check {
        padding-left: 0;

        &:last-child {

          .form-check-label {
            margin-bottom: 0;
          }
        }

        .form-check-label {
          margin-bottom: 12px;
          padding-left: 37px;
          cursor: default;
          outline-offset: 2px;

          &:has(input:focus-visible) {
            outline: $outline-focused;
            border-radius: 2px;
          }

          &:hover {

            span {

              &::before {
                border-color: $blue;
              }
            }
          }

          input {
            // display: none;
            position: absolute;
            left: -10000px;

            &:checked {

              & + span {

                &::before {
                  background-color: $blue;
                  border-color: $blue;
                  box-shadow: 0 5px 5px 0 rgb(0 0 0 / 20%), 0 10px 50px 0 rgb(74 116 255 / 30%);
                }

                &::after {
                  display: block;
                }
              }
            }

            &:disabled {

              & + span {
                cursor: default;

                &::before {
                  border-color: $light-gray;
                }
              }

              // disabled while checked
              &:checked {

                & + span {

                  &::before {
                    background-color: $light-gray;
                    border-color: $light-gray;
                    box-shadow: none;

                    @media print {
                      background-color: $white;
                    }
                  }
  
                  &::after {
                    display: block;
                    box-shadow: none;

                    @media print {
                      content: '.';
                      width: 0;
                      height: 0;
                      top: -34px;
                      left: -37.5px;
                      font-size: 100px;
                      color: $light-gray;
                    }
                  }
                }
              }
            }
          }

          span {
            display: block;
            position: relative;
            font-size: 20px;
            line-height: 32px;
            color: $content-primary;
            cursor: pointer;

            &::before,
            &::after {
              content: '';
              display: inline-block;
              position: absolute;
            }

            &::before {
              width: 22px;
              height: 22px;
              top: 5px;
              left: -37px;
              border: 2px solid $gray;
              border-radius: 50%;
              transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
            }

            &::after {
              display: none;
              top: 12px;
              left: -30px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
              background-color: $white;
            }
          }
        }
      }
    }

    .formio-errors {
      margin-top: 10px;
      // margin-left: -33px;
      // padding-left: 12px;
    }
  }

  // checkbox (single)
  .formio-component-checkbox {

    &.required {

      // wrapper
      .checkbox.form-check {

        .form-check-label {

          &::after {
            display: inline-block;
            content: ' *' / '';
            position: absolute;
            top: 4px;
            left: -16px;
            right: auto;
          }
        }
      }
    }

    // wrapper
    .checkbox.form-check {
      // padding-left: calc(24px + 1.25rem);
      padding-left: 0;

      .form-check-label {
        padding-left: 37px;
        cursor: default;
        outline-offset: 2px;

        &:has(input:focus-visible) {
          outline: $outline-focused;
          border-radius: 2px;
        }

        &:hover {

          span {

            &::before {
              border-color: $blue;
            }
          }
        }
        
        input {
          // display: none;
          position: absolute;
          left: -10000px;

          &:checked {

            & + span {

              &::before {
                color: $white;
                background-color: $primary;
                border-color: $blue;
              }
            }
          }

          &:disabled {

            & + span {
              cursor: default;

              &::before {
                border-color: $light-gray;
              }
            }

            // disabled while checked
            &:checked {

              & + span {

                &::before {
                  color: $gray;
                  background-color: $lighter-gray;
                  border-color: $lighter-gray;
                }
              }
            }
          }
        }

        span {
          display: block;
          position: relative;
          font-size: 16px;
          line-height: 28px;
          color: $content-primary;
          cursor: pointer;

          &::before {
            content: "check" / "";
            display: inline-block;
            width: 22px;
            height: 22px;
            padding: 2px 0 0 1px;
            position: absolute;
            top: 2px;
            left: -37px;
            font-family: "Material Icons";
            font-size: 15px;
            font-feature-settings: "liga";
            line-height: 1;
            color: $transparent;
            background-color: $transparent;
            border: 2px solid $unselected-light;
            border-radius: 4px;
            overflow: hidden;
            text-align: center;
            transition: box-shadow 0.3s ease, background-color 0.3s ease, border-color .3s ease, color .3s ease;
            z-index: 1;

            @media print {
              color: $transparent;
            }
          }
        }
      }
    }

    .formio-errors {
      margin-top: 10px;
      // margin-left: -33px;
      // padding-left: 12px;
    }
  }

  // checkboxes (multiple)
  .formio-component-selectboxes {

    .col-form-label {
      margin-bottom: 10px;
      padding-left: 0;
      font-size: 16px;
      line-height: 28px;
      color: $content-primary;
    }

    &.required {

      div.form-radio.radio {

        // wrapper
        .checkbox.form-check {

          .form-check-label {

            &::before {
              display: inline-block;
              content: '* ' / '';
              position: absolute;
              top: 4px;
              left: -15px;
              right: auto;
            }
          }
        }
      }
    }

    .form-radio.radio {
      // padding-left: 24px;

      // wrapper
      .checkbox.form-check {
        padding-left: 0;

        &:last-child {

          .form-check-label {
            margin-bottom: 0;
          }
        }

        .form-check-label {
          margin-bottom: 12px;
          padding-left: 37px;
          cursor: default;
          outline-offset: 2px;

          &:has(input:focus-visible) {
            outline: $outline-focused;
            border-radius: 2px;
          }

          &:hover {

            span {

              &::before {
                border-color: $blue;
              }
            }
          }
          
          input {
            // display: none;
            position: absolute;
            left: -10000px;

            &:checked {

              & + span {

                &::before {
                  color: $white;
                  background-color: $primary;
                  border-color: $blue;
                }
              }
            }

            &:disabled {

              & + span {
                cursor: default;
  
                &::before {
                  border-color: $light-gray;
                }
              }

              // disabled while checked
              &:checked {

                & + span {
  
                  &::before {
                    color: $gray;
                    background-color: $lighter-gray;
                    border-color: $lighter-gray;
                  }
                }
              }
            }
          }

          span {
            display: block;
            position: relative;
            font-size: 20px;
            line-height: 32px;
            color: $content-primary;
            cursor: pointer;

            &::before {
              content: "check" / '';
              display: inline-block;
              width: 24px;
              height: 24px;
              padding: 2px 0 0 1px;
              position: absolute;
              top: 4px;
              left: -37px;
              font-family: "Material Icons";
              font-size: 16px;
              font-feature-settings: "liga";
              line-height: 1;
              color: $transparent;
              background-color: $transparent;
              border: 2px solid $unselected-light;
              border-radius: 4px;
              overflow: hidden;
              text-align: center;
              transition: box-shadow 0.3s ease, background-color 0.3s ease, border-color .3s ease, color .3s ease;
              z-index: 1;

              @media print {
                color: $transparent;
              }
            }
          }
        }
      }
    }

    .formio-errors {
      margin-top: 10px;
      // margin-left: -33px;
      // padding-left: 12px;
    }
  }

  // location
  .formio-component-location {

    &.required {

      .field-required {

        &::after {
          // this removes the asterisk symbol when location is required
          // an asterisk symbol is added inside the location custom component
          display: none;
        }
      }
    }
  }

  // taxonomy
  .formio-component-taxonomy {

    &.has-message {

      .formio-errors {
        margin-top: 10px;
  
        .form-text.error {
          display: block;
        }
      }
    }
  }

  // error wrappers (when called checkValidity)
  .formio-component.formio-error-wrapper {
    padding: 0;
    background-color: $transparent;
    border-color: $transparent;
  }

  // generic errors
  .formio-errors {

    .form-text.error {
      display: inline-block;
      color: $urgent;
      font-size: 12px;
      letter-spacing: .5px;
      vertical-align: middle;

      &::before {
        content: 'priority_high' / '';
        display: inline-block;
        width: 16px;
        height: 16px;
        padding-top: 2px;
        margin-right: 5px;
        font-family: 'Material Icons Round';
        font-size: 10px;
        line-height: 1;
        color: $white;
        background-color: $urgent;
        border: 1px solid $urgent;
        border-radius: 50%;
        text-align: center;
      }
    }
  }
}

// submit button on bottom
.submit-button-bottom-wrap {
  padding: 0 30px 0 60px;
}

// form manager styles
.formio-form .pagination .page-item {
  cursor: pointer;
}

.null.has-success {
  display: none;
}

.formio-grid {

  .fa {
    font-size: 17px;
  }

  .btn.form-btn {
    font-size: 14px;

    &:not(.btn-danger) {
      background-color: $primary;
    }

    .fa {
      display: inline-block;
      padding: 0 3px;
      vertical-align: text-bottom;
    }
  }

  .formio-grid-footer {

    td {
      padding: 8px;
    }

    .form-btn-use {
      font-size: 14px;

      .fa {
        display: inline-block;
        padding: 0 3px;
        vertical-align: text-bottom;
      }
    }

    .pagination {

      .page-item {
          
        .page-link {
          font-size: 14px;
        }
      }
    }
  }
}

.form-group {

  .form-control {
    font-size: 14px;
  }

  .btn {
    font-size: 14px;
  }
}

.formio {

  &.builder {

    .alert {
      font-size: 14px;
    }

    .card {
      margin-bottom: 15px;
    }

    .form-control {
      font-size: 14px;
    }
  }

  .btn {
    font-size: 14px;
  }
}

form-builder {

  & + .btn.btn-primary {
    font-size: 14px;
  }
}

.page-wrapper .formcomponents .formcomponent {
  font-size: 14px;
}

.formio-dialog.formio-dialog-theme-default.component-settings {

  .formio-dialog-content {
    font-size: 14px;
  }

  .component-edit-container {

    .form-check-label {

      .form-check-input {
        display: inline-block;
        margin-left: -18px;
      }
    }

    .form-check-inline {

      .form-check-label {

        .form-check-input {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

// tooltips
div.tippy-box {
  padding: 4px 7px;
  background-color: $content-primary;

  .tippy-content {
    padding: 7px;
    font-size: 12px;
    line-height: 14px;
    color: $white;
  }

  &[data-placement^=right] {

    & > {

      .tippy-arrow {

        &::before {
          border-right-color: $content-primary;
        }
      }
    }
  }
}

// calendar in forms
.flatpickr-calendar.open {
  padding: 20px 12px 15px;
  border-radius: 16px;
  box-shadow: 0 5px 10px #a8a8a833, 0 10px 40px #efefef4d;
  background-color: $white;
  border: none;

  .flatpickr-months {

    .flatpickr-prev-month {
      left: 10px;
    }

    .flatpickr-next-month {
      right: 10px;
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
      top: 18px;

      &:hover {

        svg {
          opacity: 1;
        }
      }

      svg {
        width: 14px;
        height: 14px;
        fill: $icon-blue;
        opacity: .7;
        transition: opacity .3s ease;
      }
    }

    .flatpickr-month {

      .flatpickr-current-month {

        .flatpickr-monthDropdown-months,
        .numInputWrapper {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          color: $content-primary;
        }
      }
    }
  }

  .flatpickr-innerContainer {
    margin-top: 18px;

    .flatpickr-rContainer {
      width: 100%;

      .flatpickr-weekdays {
        width: 100%;
        margin-bottom: 8px;
        background-color: #f4f6f8;

        .flatpickr-weekdayscontainer {

          .flatpickr-weekday {
            font-size: 12px;
            font-weight: 700;
          }
        }
      }

      .flatpickr-days {
        width: 100%;

        .dayContainer {
          min-width: 100%;
          width: 100%;

          .flatpickr-day {
            margin-top: 2px;
            font-size: 14px;
            font-weight: 700;
            color: $content-primary;

            &:hover {
              background-color: #eaeaea;
            }

            &.prevMonthDay,
            &.nextMonthDay {
              color: $unselected-light;
            }

            &.today {
              border-color: $transparent;

              &::before {
                display: inline-block;
                content: "";
                width: 4px;
                height: 4px;
                position: absolute;
                bottom: 2px;
                left: 50%;
                border-radius: 50%;
                background-color: $blue;
                transform: translate(-50%);
              }
            }

            &.selected {
              color: $white;
              background-color: $blue;
              box-shadow: 0 0 10px #7b7b7b33, 0 5px 40px #d7e0ff4d;
            }
          }
        }
      }
    }
  }
}
