// TODO - hide elements that should not be seen on printed page

.table-print {
  width: 100%;

  .table-header,
  .header-space,
  .table-footer,
  .footer-space {
    display: none;
  }
}

.first-page {
  display: none;
}

.footer-print {
  display: none;
}

// @media print and (max-width: 993px) {

//   body {
//     min-width: 0 !important;
//     max-width: 100vw;
//   }
// }

@media print {

  // this fixes issue with specific print paper sizes - the content (body element) disappeared
  // probbaly due to wrong calculations because of our custom scrollbar size
  ::-webkit-scrollbar {
    width: auto !important;
  }

  @page {
    size: auto;
  }

  html {
    height: 100%;
    font-size: 100%;
  }

  body {
    height: 100%;
    // min-width: 0 !important;
    font-size: 16px;
  }

  .print-page-separator {
    page-break-after: always;
  }

  .first-page {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
    // z-index is big to go above the print footer
    background-color: $white;
    z-index: 1000;

    .first-page-content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .comp-branding {
        text-align: center;

        .logo-wrap {
          display: block;

          img {
            max-width: 286px;
            height: auto;
          }
        }

        .comp-name {
          display: inline-block;
          margin-top: 30px;
          font-size: 28px;
        }
      }

      .content-title {
        margin: 30px 0 0;
        font-size: 40px;
        text-align: center;
      }
    }

    .first-page-footer {
      position: absolute;
      right: 0;
      bottom: 60px;
      left: 0;

      .carrot-logo {
        text-align: center;

        img {
          max-width: 180px;
        }
      }

      .text-wrap p {
        margin-top: 15px;
        font-size: 18px;
        text-align: center;
      }
    }
  }

  // header
  .custom-navbar-outer-wrapper {
    display: none;
  }

  .table-print {
    display: table;
    margin: 0 auto;
    table-layout: fixed;

    .table-header {
      display: table-header-group;
      position: relative;
      z-index: 999;

      .header-space {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;

        .left-part {
          display: flex;
          justify-content: center;
          align-items: center;

          .logo-wrap {
            display: inline-block;

            img {
              width: auto;
              height: 40px;
            }
          }

          .comp-name {
            display: inline-block;
            padding-left: 20px;
            font-size: 18px;
          }
        }

        .right-part {

          .title {
            display: inline-block;
            font-size: 18px;
          }
        }
      }
    }

    .table-body {
      display: table-row-group;
    }

    .table-row {
      display: table-row;
    }

    .table-data {
      display: table-cell;
    }

    .table-footer {
      display: table-footer-group;
      position: relative;
      z-index: 999;

      .footer-space {
        display: block;
        height: 60px;

        .footer-print {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 60px;
          position: fixed;
          right: 0;
          bottom: 0;
          left: 0;
          text-align: center;
          z-index: 30;
        }
      }
    }
  }
}

// styles for PDF generation only (currently only on forms)
.pdf-generation {

  @media print {

    // weird fix for generating pdfs - footer is overlapping the content on the very last page
    // presumably when the content takes less than half of the screen
    .main {
      height: auto !important;
      padding-bottom: 0 !important;
    }

    .table-print .table-footer .footer-space .footer-print {
      position: relative;
    }
  }
}