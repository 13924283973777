@import "src/scss/vars.scss";

.notification-small {
  font-family: $font-family-primary;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  height: 48px;
  display: inline-block;
  cursor: auto;
  box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
    0 5px 40px 0 rgba(239, 239, 239, 0.3);

  .notification-small-icon,
  .notification-small-text {
    display: inline-block;
    vertical-align: middle;
  }

  .notification-small-icon {
    padding: 0px 4px 4px;
    border-radius: 50%;
    color: #34b354;
    background-color: #d7f1de;

    .icon {
      font-size: 20px;
      vertical-align: middle;
    }
  }

  .notification-small-text {
    margin-left: 13px;
    margin-top: 2px;
  }

  &--white {
    padding: 10px 15px;
    background-color: $white;
    color: $text-color-standard;
    .notification-small-icon {
      box-shadow: 0 0px 10px 0 rgba(123, 123, 123, 0.3),
        0 0px 20px 0 rgba(239, 239, 239, 0.3);
    }
  }
  &--green {
    padding: 10px 23px 15px 18px;
    color: $white;
    background-color: $green-solid;
    box-shadow:0 0px 10px 0 rgba(99,99,99,0.2),
    0 0px 20px 0 rgba(53,182,87,0.3);
    .notification-small-icon {
      padding: 0;
      height: 16px;
      width: 16px;
      background-color: $white;
      .icon {
        font-size: 14px;
        padding-top: 2px;
        position: absolute;
        padding-left: 1px;
      }
    }
  }

  &--position-right {
    position: absolute;
    right: -180px;
    bottom: 0px;
    top: 0px;
  }
}
