// local fix for global html class
.modal-opened {

  .general-setup-container {

    .component-header {

      &.on-top {
        left: calc(50% - 6px);
      }
    }
  }
}

.general-setup-container {
  position: relative;
  padding-top: 94px;

  .component-header {

    .general-setup-title {
      margin: 0;
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
      color: $text-color-standard;
    }

    .steps-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 1px;

      @media screen and (max-width: 992px) {
        margin-top: 15px;
      }

      .step {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 40px;

        &.in-progress {

          .step-number-wrap {
            background-color: #D7E0FF;
            box-shadow: 0 0 10px 0 rgba(123,123,123,0.2);

            .step-number {
              color: #4D71FE;
            }
          }

          .step-text-wrap {

            .step-title {
              color: #4D71FE;
            }
          }
        }

        &.complete {

          .step-number-wrap {
            background-color: #D7F1DE;
            box-shadow: 0 0 10px 0 rgba(123,123,123,0.2);

            .step-number {
              color: #34B354;

              .icon {
                font-size: 15px;
                vertical-align: middle;
              } 
            }
          }

          .step-text-wrap {

            .step-progress {
              color: #35B657;
            }
          }
        }

        .step-number-wrap {
          display: inline-block;
          width: 24px;
          height: 24px;
          margin-right: 14px;
          position: relative;
          background-color: #AFB3C1;
          border-radius: 50%;

          .step-number {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            color: $white;
            transform: translate(-50%, -50%);
          }
        }

        .step-text-wrap {
          display: inline-block;

          .step-title {
            display: block;
            font-size: 14px;
            line-height: 17px;
            font-weight: 700;
            color: #848998;
          }

          .step-progress {
            display: block;
            font-size: 10px;
            line-height: 12px;
            color: #AFB3C1;
            transform: translateY(3px);
          }
        }
      }
    }
  }
  
  .component-body {
    padding: 38px 63px 0;
    position: relative;
    z-index: 2;

    .group {
      padding-bottom: 10px;
      margin-bottom: 45px;

      &.zero-stage {
        margin-top: 20px;

        .zero-stage-setup {
          display: flex;
          justify-content: flex-end;
        }

        .group-title {
          margin: 24px 0 0;
        }
      }

      &:last-child {
        margin-bottom: 8px;
      }

      &.one-line {
        margin-bottom: 50px;

        &:first-child {
          margin-top: 10px;
        }

        &:last-child {
          margin-bottom: 8px;
        }

        .group-title {
          margin-top: 6px;
        }
      }

      .group-title {
        margin: 14px 0 11px;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: $text-color-standard;
        display: flex;
        gap: 10px;

        button {
          display: flex;
          align-items: center;

          .material-icons {
            font-size: 22px;
          }
        }
      }

      .group-body {

        & > .row {
          margin-left: -12px;
          margin-right: -12px;

          & > div[class^="col-"] {
            padding: 0 12px;
          }
        }

        .general-setup-label {
          position: relative;
          padding: 12px 0;
          width: 100%;
          margin-bottom: 0;

          .input-direct-wrap {
            display: block;
            position: relative;
            overflow: hidden;
          }

          .general-setup-input {
            height: 48px;
            width: 100%;
            padding: 26px 14px 11px;
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            color: #5E616D;
            background-color: $white;
            border: 1px solid #E3E4E9;
            border-radius: 8px;
            transition: box-shadow .3s ease;
            z-index: 1;
            display: block;

            &:focus,
            &.has-value {

              & + .general-setup-placeholder {
                top: 27px;
                font-size: 10px;
              }
            }

            &:focus {
              box-shadow: 0 5px 10px 0 rgba(168,168,168,0.2),
                          0 10px 40px 0 rgba(239,239,239,0.3);

              & + .general-setup-placeholder {
                color: #4D71FE;
              }
            }

            &.has-value {

              & + .general-setup-placeholder {
                color: #848998;
              }
            }

            &.errored {
              border-color: #F34A4A;

              &.errored-soft {
                border-color: #FFCB4A;                
              }

              &.errored-real {
                border-color: #F34A4A;
              }
            }
          }

          .general-setup-placeholder {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 15px;
            font-size: 14px;
            line-height: 17px;
            color: #848998;
            transform: translateY(-50%);
            transition: top .3s ease, font-size .3s ease, line-height .3s ease, color .3s ease;
            user-select: none;
            pointer-events: none;
            z-index: 2;

            &.filled {
              top: 27px;
              font-size: 10px;
              color: #848998;
            }
            &.require {
              color: red !important;
              top: 27px !important;
              font-size: 10px !important;
            }
          }

          .icon {

            &.icon-right {
              display: inline-block;
              width: 20px;
              height: 20px;
              padding: 1px 0;
              position: absolute;
              top: 25px;
              right: 15px;
              font-size: 20px;
              color: #AFB3C1;
              cursor: pointer;
              border-radius: 50%;
              transition: color .3s ease;
              text-align: center;
              user-select: none;
              z-index: 1;

              &:hover {
                color: darken(#AFB3C1, 15%);
              }
            }
          }

          .error-field {
            display: inline-block;
            padding-top: 10px;
            // position: absolute;
            // bottom: -20px;
            // left: 0;

            .error-message {
              display: block;
              padding-top: 2px;

              &.error-message-soft {

                .error-icon {
                  color: #FFCB4A;
                  border-color: #FFCB4A;
                }
              }

              .error-icon {
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 5px;
                font-size: 14px;
                line-height: 1;
                color: #F34A4A;
                border: 1px solid #F34A4A;
                border-radius: 50%;
                vertical-align: middle;
                text-align: center;
              }

              .error-text {
                display: inline-block;
                color: #3D3F46;
                font-size: 12px;
                vertical-align: middle;
              }
            }
          }
        }

        .general-setup-label-radio {
          width: auto;
          cursor: pointer;

          .general-setup-input-radio {
            position: absolute;
            opacity: 0;
            visibility: hidden;

            &:checked {

              & + .general-setup-radio-checkbox {

                .checkbox {
                  // border: 7px solid #4E6FFE;
                  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);

                  &::after {
                    width: calc(100% + 4px);
                    height: calc(100% + 4px);
                  }
                }
              }
            }
          }

          .general-setup-radio-checkbox {
            display: inline-block;

            .checkbox {
              display: inline-block;
              width: 22px;
              height: 22px;
              margin-right: 15px;
              position: relative;
              background-color: $white;
              border: 2px solid #AFB3C1;
              border-radius: 50%;
              box-sizing: border-box;
              transition: border .2s ease, box-shadow .2s ease;
              vertical-align: middle;

              &::after {
                display: inline-block;
                content: '';
                width: 0;
                height: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: #4E6FFE;
                border-radius: 50%;
                transition: width .3s ease, height .3s ease;
                z-index: 1;
              }

              &::before {
                display: inline-block;
                content: '';
                width: 8px;
                height: 8px;
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: $white;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
              }
            }

            .title {
              display: inline-block;
              font-size: 14px;
              font-weight: 700;
              line-height: 17px;
              color: $text-color-standard;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .component-footer {
    padding: 20px 63px 40px;
    position: relative;
    z-index: 2;
  }
}

.save-changes-button-wrapper {
  text-align: right;
}

// dragula
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}