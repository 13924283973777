/**  =====================
      Generic-class css start
========================== **/

/*====== text-color, background & border css starts ======*/
@each $value in $color-name {
    $i: index($color-name, $value);
    .bg-#{$value} {
        background: linear-gradient(45deg, nth($color-color,$i), lighten(nth($color-color,$i),10%));
    }
    .text-#{$value} {
        color: nth($color-color,$i);
    }
    .b-#{$value} {
        border: 1px solid nth($color-color,$i);
    }
}
/*====== text-color, background & border css ends ======*/
/*====== Font-size css starts ======*/
$i: 10;
@while $i<=80 {
    .f-#{$i} {
        font-size: #{$i}px;
    }
    $i: $i+2;
}
/*====== Font-size css ends ======*/
