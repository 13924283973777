.button-blue {
  display: inline-block;
  min-width: 150px;
  padding: 13px 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: $white;
  background-color: #4E6FFE;
  border: 2px solid $transparent;
  border-radius: 8px;
  transition: color .3s ease, background-color .3s ease, border-color .3s ease, box-shadow .3s ease;


  &:focus {
    outline: none;
  }

  &--dropdown {
    position: relative;
    padding-right: 76px;

    &:hover {

      .icon-arrow {
        border-color: $white;
      }
    }

    &.dropdown-expanded {

      .icon-arrow {
        transform: translateY(-50%) rotate(-225deg);
      }
    }

    .icon-arrow {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 20px;
      width: 7px;
      height: 7px;
      border-bottom: 2px solid $icon-blue;
      border-left: 2px solid $icon-blue;
      pointer-events: none;
      transform: translateY(-50%) rotate(-45deg);
      transition: transform .3s ease, border-color .3s ease;
    }
  }

  &--transparent {
    color: #4D71FE;
    background-color: $white;
    border-color: #D7E0FF;

    &:hover {
      color: $white;
      background-color: #4D71FE;
      border-color: #4D71FE;
    }
  }

  &--has-shadow {

    &:hover {
      box-shadow: 0 10px 40px 0 rgba(74,116,255,0.3);
    }
  }

  &--medium {
    padding: 11px 15px 10px;
    font-size: 14px;
    line-height: 17px;
  }

  &--small {
    min-width: 0;
    padding: 6px 14px;
    font-size: 12px;
    line-height: 15px;
  }

  &.disabled {
    color: #ccc;
    background-color: #fbfbfb;
    border-color: #ececec;
    cursor: not-allowed;

    &:hover {
      color: #ccc;
      background-color: #fbfbfb;
      border-color: #ececec;
      box-shadow: none;
    }
  }
}

.disabledButton {
  background-color: #E3E4E9;
  color: #B8BCC8;

  &:hover {
    background-color: #E3E4E9;
    color: #B8BCC8;
    border-color: #E3E4E9;
    box-shadow: none;
  }
}

.changes_saved {
  font-size: 16px;
  font-weight: bold;
  padding: 14px 18px 15px 12px;
  border-radius: 8px;
  border: none;
  position: absolute;
  cursor: auto;
  background-color: $white;
  box-shadow: 0 5px 10px 0 rgba(168,168,168,0.2), 0 5px 40px 0 rgba(239,239,239,0.3);
  right: -180px;
  bottom: 0px;
  top: 0px;

  .changes_saved__icon,
  .changes_saved__text {
    float: left;
  }

  .changes_saved__icon {
    padding: 0px 4px 4px;
    border-radius: 50%;
    color: #34B354;
    background-color: #D7F1DE;

    .icon {
      font-size: 20px;
      vertical-align: middle;
    }
  }

  .changes_saved__text {
    margin-left: 13px;
  }
}

.button-restore-defaults {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background-color: #AFB3C1;
  border: none;
  border-radius: 4px;
  transition: background-color .3s ease;

  &:hover {
    background-color: #9298ab;
    border: none;
    outline: none;
  }

  &:focus {
    border: none;
    outline: none;
  }

  &:focus-visible {
    outline: $outline-focused;
  }

  .icon {
    font-size: 16px;
    color: $white;
    vertical-align: middle;
  }
}
