@import "src/scss/vars.scss";

.menu-toggle {
  display: inline-block;
  width: 40px;
  height: 24px;
  position: relative;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
  border-color: transparent;
  background-color: transparent;

  &:not([disabled]) {
    color: #4A74FF;

    &:focus {
      outline: $outline-focused;
    }

    &:hover {
      box-shadow: 0 3px 15px #aeaec066;
    }
  }

  &[disabled] {
    color: $icon-gray-disabled;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.p-menu.p-menu-overlay {
  width: auto;
  border: none;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 10px 40px #94949433, 0 5px 10px #dadada26;
  margin-top: 5px;
  
  ul {
    padding: 5px;
  }

  .p-submenu-header {
    display: none;
  }

  .p-menuitem {
    border-radius: 2px;

    &.p-focus {
      outline: $outline-focused;
    }

    &.red {

      .p-menuitem-link {

        .p-menuitem-icon {
          color: #f34a4a;
        }

        .p-menuitem-text {
          color: #f34a4a;
        }
      }
    }

    &:not(.red) {

      .p-menuitem-link {

        .p-menuitem-icon {
          color: $light-text;
        }

        .p-menuitem-text {
          color: $text-color-standard;
        }
      }
    }

    .p-menuitem-content {
      background-color: $transparent;
    }

    .p-menuitem-link {
      display: block;
      padding: 5px 15px;
      margin: 2px 0;
      border-radius: 8px;
      cursor: pointer;
      transition: box-shadow .3s ease;

      &.p-disabled {
        pointer-events: auto;
        cursor: not-allowed;

        .p-menuitem-icon {
          color: $unselected-light;
        }

        .p-menuitem-text {
          color: $unselected-light;
        }
      }

      &:not(.p-disabled) {

        &:hover {
          box-shadow: 0 3px 15px 0 rgba(174,174,192,0.2);
          background: none;
        }

        &:focus-visible {
          outline: $outline-focused;
        }
      }

      .p-menuitem-icon {
        display: inline-block;
        font-size: 20px;
        vertical-align: middle;
      }

      .p-menuitem-text {
        display: inline-block;
        font-family: $font-family-primary;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
        vertical-align: middle;
      }
    }
  }
}

.icon-csv {
  width: 18px;
  height: 18px;
  opacity: 0.7;
  margin-right: 10px !important;
  background-size: 100%;
  background-image: url("../../assets/fonts/csv.svg");
}

.icon-excel {
  width: 18px;
  height: 18px;
  opacity: 0.7;
  margin-right: 10px !important;
  background-size: 100%;
  background-image: url("../../assets/fonts/excel.svg");
}