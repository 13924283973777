/**======================================================================
=========================================================================
Template Name: Gradient Able Admin Template
Author: Codedthemes
supports: https://codedthemes.com/submit-ticket/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   authentication, Maintenance, Maps, Landing page messages, task, Todo, Notes, Charts, Icons, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */

// Global
@import "forms";


// main framework
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "settings/theme-variables";


// bootstrap
@import "bootstrap/scss/bootstrap";

@import "themes/general";
@import "themes/generic";
@import "themes/plugin/animate";

// theme layouts
@import "themes/layouts/menu-lite";

// prime-ng
@import "primeng/resources/themes/nova/theme.css";
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import "quill/dist/quill.snow.css";

// Added styles
// vars, helpers & fonts
@import "vars.scss";
@import "helpers.scss";
@import "fonts.scss";

// animations
@import "animations.scss";

// grid
@import "reflex.scss";

// base styles
@import "normalize.scss";
@import "base.scss";

// general styles
@import "buttons.scss";

// component styles
@import "general-setup.scss";

@import "customPickerStyle.scss";

// Kitchen Components
@import "theme-2.0/button.scss";
@import "theme-2.0/dropdown.scss";
@import "theme-2.0/checkbox.scss";
@import "theme-2.0/radio.scss";
@import "theme-2.0/input.scss";
@import "theme-2.0/shared.scss";
@import "theme-2.0/icon-buttons.scss";
@import "theme-2.0/toggle.scss";
@import "theme-2.0/link.scss";
@import "theme-2.0/headers.scss";
@import "theme-2.0/notification-small.scss";
@import "theme-2.0/notification-medium.scss";
@import "theme-2.0/notification-large.scss";
@import "theme-2.0/multiselect.scss";
@import "theme-2.0/singleselect.scss";
@import "theme-2.0/menu.scss";
@import "theme-2.0/dialog.scss";

// pages
@import "login-page.scss";

// print styles
@import "print.scss";

// plugins
@import "dragula/dist/dragula.css";

//widgets
@import "theme-2.0/widgets.scss";


