.p-tooltip {
  max-width: none;

  @media print {
    display: none !important;
  }

  .p-tooltip-text {
    padding: 10px 13px;
    font-size: 12px;
    white-space: nowrap;
  }

  .p-tooltip-arrow {
    scale: 1;
  }

  &.p-tooltip-bottom {
    transform: translateY(8px);

    .p-tooltip-arrow {
      top: -6px;
      border-width: 1px 8px 8px;
      margin: 0;
      transform: translateX(-50%);
      }
    }

  &.p-tooltip-top {
    transform: translateY(-8px);

      .p-tooltip-arrow {
        border-width: 8px 8px 7px;
        bottom: -12px;
        margin: 0;
        transform: translateX(-50%);
      }
  }

  &.p-tooltip-left {
    transform: translateX(-8px);

      .p-tooltip-arrow {
        right: -4px;
        margin: 0;
        transform: translateY(-50%);
        border-width: 7px 0 7px 7px;
      }
  }

  &.p-tooltip-right {
    transform: translateX(8px);

      .p-tooltip-arrow {
        left: -4px;
        margin: 0;
        transform: translateY(-50%);
        border-width: 7px 7px 7px 0;
      }
  }
}

.rotate {
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}