/* ==========================================================================
   Helper classes
   ========================================================================== */
.hidden, .hide {
  display: none !important;
}

.invisible, .invis {
  opacity: 0;
}

.clearfix, .clear {
  // .clearfix();
}

.left, .l {
  float: left !important;
}

.right, .r {
  float: right !important;
}

.tCenter, .tc {
  text-align: center;
}

.tLeft, .tl {
  text-align: left;
}

.tRight, .tr {
  text-align: right;
}

.relative, .rel {
  position: relative;
}

.absolute, .abs {
  position: absolute;
}

.dBlock {
  display: block;
}

.dFlex {
  display: flex;
}


// COLOR HELPERS 
// TODO - remove those
.blue {
  color: $blue !important;
}

.black {
  color: $black !important;
}


// MARGIN HELPERS //
// margin
.m-24 {
  margin: 24px;
}
.m-12 {
  margin: 12px;
}

// margin-right
.mr-12 {
  margin-right: 12px;
}

// margin-bottom
.mb-24 {
  margin-bottom: 24px;
}

.mb-66 {
  margin-bottom: 66px;
}

// margin-top
.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-46 {
  margin-top: 46px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-66 {
  margin-top: 66px;
}

// margin-left
.ml-18{
  margin-left: 18px;
}

.ml-24 {
  margin-left: 24px;
}

// margin x (top & bottom 0 on left and right)
.mx-24 {
  margin: 24px 0;
}

.mx-30 {
  margin: 30px 0;
}

.mx-40 {
  margin: 40px 0;
}


// PADDINGS 
// Change into padding classes with spacer
.p-0 {
  padding: 0;
}

// TODO - remove important
.pt-20 {
  padding-top: 20px !important;
}

.pl-30 {
  padding-left: 30px;
}

.pb-66 {
  padding-bottom: 66px;
}

.w100 {
  width: 100%;
}