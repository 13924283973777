@import "src/scss/vars.scss";

.notification-large {
  font-family: $font-family-primary;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  height: 120px;
  cursor: auto;
  display: inline-flex;
  box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
    0 5px 40px 0 rgba(239, 239, 239, 0.3);

  .notification-position {
    display: inline-flex;
    .img-position {
      padding: 39px 0px 30px 39px;
      img{
          height: 42px;
          width: 42px;
      }
    }
    .text {
      padding: 41px 39px 39px 30px;
      display: inline-grid;
      .title {
        font-size: 18px;
        color: $text-color-standard;
        height: 20px;
      }
      .subtitle {
        font-size: 14px;
        color: $grey-text;
        font-weight: normal;
      }
    }
    .button-position {
      padding: 36px 30px 36px 20px;
    }
    .redirect-link {
        height: 60px;
        font-size: 14px;
        padding: 21px 39px;
        margin-top: 30px;
        color: $unselected-light;
        text-transform: uppercase;
        border-left: 1px solid $disabled;
        cursor: pointer;
    }
    .icon-wrapper {
      padding: 43px 0px 43px 30px;
      .icon-position {
        padding: 7px 9px;
        border-radius: 50%;
        color: #34b354;
        background-color: #d7f1de;
        box-shadow: 0 0px 10px 0 rgba(123, 123, 123, 0.3),
          0 0px 20px 0 rgba(239, 239, 239, 0.3);
        .icon {
          font-size: 20px;
          vertical-align: middle;
          padding-bottom: 2px;
        }
      }
    }
  }
}
