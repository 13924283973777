@import "src/scss/vars.scss";

.loginPageVisible {

  .pcoded-main-container {

    @media screen and (max-width: 1440px) {
      max-width: 100%;
    }
  }
}

.login-page-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease, visibility 0s ease .5s;
  z-index: 1;

  &.shown {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
}

.image-credit {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: $white;
  background: rgb(55 67 87 / 90%);
  position: absolute;
  right: 25px;
  bottom: 25px;
  z-index: 1000;
  cursor: pointer;

  &:focus-visible {
    outline: $outline-focused;
  }

  &:hover {
    color: $white;
  }
}

.login-page {

  &.initial-loading {

    .login-loader {
      animation-name: disappearUpToDown;
      animation-duration: .3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }

    .login-widget {
      animation-name: appearDownToUp;
      animation-duration: .3s;
      animation-delay: .3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
    
    .login-page-footer {
      animation-name: appearOpacity;
      animation-duration: .3s;
      animation-delay: .3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  .login-loader {
    position: absolute;
    top: 40px;
    right: 0;
    bottom: 40px;
    left: 0;
    transform-origin: 50% 50%;

    .loader-inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .loader-image {
      text-align: center;
      margin-bottom: 40px;
      z-index: 2;

      img {
        width: auto;
        height: 40px;
        transform: translateX(-4px);
      }
    }

    .loader-progress {
      width: 180px;
      height: 6px;
      margin: 0 auto;
      position: relative;
      background-color: rgba($black, .1);
      border-radius: 3px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: 3px;
        background-color: $black;
        animation-name: fillToRightLoading;
        animation-duration: 1.8s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }
    }
  }

  .login-widget {
    opacity: 0;
    visibility: hidden;

    &.loading {
      overflow: visible;

      .login-widget-content {
        cursor: progress;

        .login-widget-branding {

          .loader {
            transform: translateX(-50%) scale(1);
          }
        }

        .input-wrap {

          input,
          .input-label {
            cursor: progress;
          }
        }
  
        .button-wrap {
  
          .button-login {
            cursor: progress;
          }
        }
      }
    }

    .login-widget-content {
      width: 360px;
      margin: 0 auto;
      padding: 20px 36px 32px;
      background-color: $white;
      border-radius: 16px;
      box-shadow: 10px 10px 30px #aeaec066;
  
      .login-widget-branding {
        padding: 32px 25px;
        border-bottom: 1px solid #ddd;
        position: relative;
  
        img {
          display: block;
          margin: 0 auto;
          max-width: 205px;
          max-height: 110px;
        }

        .loader {
          display: inline-block;
          width: 40px;
          height: 40px;
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%) scale(0);
          background-color: $white;
          box-shadow: 0 0 0 10px #fff;
          transform-origin: 50% 50%;
          transition: transform .3s ease;
  
          .loader-inner {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 2px solid rgb(243, 243, 243);
            border-radius: 50%;
  
            &::before {
              content: '';
              display: inline-block;
              position: absolute;
              top: -2px;
              right: -2px;
              bottom: -2px;
              left: -2px;
              border-top: 2px solid $primary;
              border-right: 2px solid rgb(243, 243, 243);
              border-left: 2px solid rgb(243, 243, 243);
              border-radius: 50%;
              transform: rotate(45deg);
              animation-name: rotateRight;
              animation-iteration-count: infinite;
              animation-duration: 1.2s;
              animation-timing-function: linear;
            }
          }
        }
      }

      .login-form-wrap {

        .title {
          margin: 24px 0 16px;
          font-size: 20px;
          font-weight: 600;
          line-height: 32px;
          color: $black;
        }

        .subtitle {
          margin: 0 0 8px;
          font-size: 14px;
          line-height: 20px;
          color: #727685;

          &.extra-margin {
            margin: 20px 0 16px;
          }
        }

        .validation-rules-wrap {
          padding-top: 6px;

          .password-requirements {
            font-size: 14px;
            line-height: 20px;
            color: #727685;

            .txt {
              display: inline-block;
              margin-bottom: 10px;
            }

            .password-requirements-list {

              .icon {
                margin-left: 4px;
                font-size: 15px;
                font-weight: 700;
                color: $no-error;
                transform: translateY(3px);
              }
            }
          }
        }

        .action-failed {

          p {
            position: relative;
            padding: 3px 0 3px 21px;
            margin: 0 0 5px;
            font-size: 12px;
            line-height: 14px;
            color: $urgent;

            &::before {
              display: inline-block;
              content: '!';
              width: 16px;
              height: 16px;
              position: absolute;
              top: 2px;
              left: -1px;
              font-family: $font-family-primary;
              font-size: 13px;
              letter-spacing: -0.9px;
              line-height: 16px;
              color: $urgent;
              border: 1px solid $urgent;
              border-radius: 50%;
              text-align: center;
            }
          }
        }

        // .input-wrap {
        //   padding-top: 12px;
        // }

        .extra-buttons-wrap {
          margin: 1px auto 22px;
          text-align: center;
  
          .button-forgotten-pass {
            font-size: 12px;
            color: #727685;
            border-radius: 2px;
            outline-offset: 4px;

            &:focus-visible {
              outline: $outline-focused;
            }
          }
        }

        .bottom-button-wrap {
          padding-top: 20px;
          padding-bottom: 50px;
          font-size: 14px;
          color: #727685;
          text-align: center;

          a {
            display: inline-block;
            margin-left: 5px;
            font-weight: 600;
            color: #305FFF;
            cursor: pointer;
            transition: color .3s ease;
            border-radius: 2px;
            outline-offset: 2px;

            &:focus-visible {
              outline: $outline-focused;
            }

            &:hover {
              color: $primary-hover;
            }
          }
        }

        .bottom-back-wrap {
          border-top: 1px solid #ddd;
          text-align: center;
          padding: 20px 20px 0 20px;

          a {
            display: inline-block;
            margin-left: 5px;
            font-weight: 600;
            color: #305FFF;
            cursor: pointer;
            transition: color .3s ease;
            border-radius: 2px;
            outline-offset: 4px;

            &:focus-visible {
              outline: $outline-focused;
            }

            &:hover {
              color: $primary-hover;
            }
          }
        }
      }
    }
  }
}
