@import "src/scss/vars.scss";

.reg-header {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-bottom: 1px solid $transparent;
  z-index: 2;

  &.on-top {
    position: fixed;
    // width: 1143px;
    top: 10px;
    background-color: $white;
    z-index: 22;

    &.on-top-with-header {
      // true styles
      top: 0;
      border-bottom-color: $lighter-gray;

      .header-wrap {
        padding: 10px 24px;
      }
    }
  }
}

.header-wrap {
  margin: 0 auto;
  display: flex;
  padding: 26px 24px;
  transition: padding .3s ease;

  &.demo-wrap {
    //TODO: This is only for KS Purposes.
    // This box-shadow should be transfered when the header gets added to it's component
    box-shadow: 0 5px 10px 0 rgba(168, 168, 168, 0.2),
      0 10px 40px 0 rgba(239, 239, 239, 0.3);
    height: 62px;
  }

  .header-title {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: $content-primary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & + .progress-bar-wrap {
      padding-left: 20px;
    }
  }

  .auto-save-icon {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 25px 0 5px;
    font-size: 14px;

    &::before {
      content: '';
      width: 1px;
      height: 28px;
      position: absolute;
      top: 50%;
      right: 0;
      background-color: $lighter-gray;
      transform: translateY(-50%);
    }

    .save-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      height: auto;
      padding: 5px;
      color: $gray;
    }

    .material-icons-round {
      font-size: 16px;
    }
  }

  .progress-bar-wrap {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 24px;

    .progress-bar-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      white-space: nowrap;
      color: $content-contrast;
    }

    .customize-progress-bar {
      width: 100%;
      padding-left: 12px;

      &.grey {

        .p-progressbar-value {
          background-color: $scroll;
        }
      }

      &.blue {

        .p-progressbar-value {
          background-color: $secondary-hover;
        }
      }

      &.green {

        .p-progressbar-value {
          background-color: $green;
        }
      }

      &.darkgrey {

        .p-progressbar-value {
          background-color: $unselected-light;
        }
      }

      .p-progressbar {
        margin-top: 2px;
        height: 4px;
        min-width: 150px;

        .p-progressbar-label {
          display: none !important;
        }
      }
    }
  }

  .next-prev-wrap {
    display: flex;
    align-items: center;
    padding: 0 0 0 24px;

    &.disable-double-button {

      button:first-of-type {

        &::after {
          background-color: $disabled;
        }
      }
    }

    button {
      outline-offset: -3px;
    }

    button:first-of-type {
      border-right: none;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      &:after {
        content: "";
        background-color: $secondary;
        position: absolute;
        margin-left: 9px;
        margin-top: 3px;
        height: 18px;
        width: 1px;
      }
    }

    button:last-of-type {
      border-left: none;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  .submitted-details-wrap {
    display: flex;
    align-items: center;
    margin: 0 0 0 8px;
    white-space: nowrap;

    &.submitted {

      .deadline-details {
        // margin-right: 25px;
        background-color: #D1EFD8;
        white-space: nowrap;

        &.deadline-approaching {
          margin-right: 0;
          padding-right: 25px;
          border-radius: 8px;

          @media only screen and (max-width: 1200px) {
            height: 36px;
            padding-right: 16px;
          }
        }

        .material-icons {
          color: #19AE3A;
        }

        .submitted-text {
          color: #19AE3A;
          border-right: 1px solid #19AE3A;
        }
      }
    }

    &.not-submitted {

      .deadline-details {
        // margin-right: 25px;
        background-color: #F9D6D1;
        white-space: nowrap;

        .material-icons {
          color: #E03019;
        }

        .submitted-text {
          color: #E03019;
          border-right: 1px solid #E03019;
        }
      }
    }

    &.deadline-passed {

      .deadline-details {
        // margin-right: 25px;
        background-color: #F9D6D1;

        .material-icons {
          color: #E03019;
        }

        .submitted-text {
          color: #E03019;
          border-right: 1px solid #E03019;
        }
      }
    }

    .deadline-details {
      display: flex;
      padding: 10px 10px 9px 10px;
      border-radius: 8px;

      .material-icons {
        padding-right: 8px;
        font-size: 21px;
      }

      .submitted-details {
        display: flex;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: $content-primary;
  
        .submitted-text {
          display: inline-block;
          padding-right: 8px;
          margin-right: 8px;
  
          .by {
            color: $content-primary;
          }
        }
  
        .submitted-date {
          display: flex;
          color: $content-primary;
  
          span:last-of-type {
            padding-left: 5px;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .additional-icons-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    margin-left: 10px;

    .print-icon,
    .view-icon {
      width: 30px;
    }

    .button-link {
      height: 30px;
      width: 30px;

      &:focus-visible {
        outline: $outline-focused;
        outline-offset: -2px;
      }
    }

    .material-icons-round {
      font-size: 20px;
    }
  }

  .action-button-wrap {

    button {
    }
  }

  @media only screen and (max-width: 1200px) {

    .submitted-details-wrap {
      margin: 13px 5px;

      &.not-submitted,
      &.submitted {
        background-color: $white;

        .deadline-details {
          margin-right: 10px;
        }
      }

      .submitted-details {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 991px) {

    .customize-progress-bar {
      display: none;
    }
  }
}
