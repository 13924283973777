@keyframes fillToRight {
  0% {
    width: 0;
  }
  80% {
    width: calc(100% + 20px);
  }
  100% {
    width: calc(100% + 20px);
  }
}

@keyframes fillToRightLoading {
  0% {
    width: 0;
  }
  80% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@keyframes rotateRight {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

@keyframes appearDownToUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes disappearUpToDown {
  0% {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes appearOpacity {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes pulse {
  0% {
    background-color: #F2F3F5;
  }

  50% {
    background-color: #F9F9FA;
  }

  100% {
    background-color: #F2F3F5;
  }
}

@keyframes pulseBorder {
  0% {
    border-color: #F2F3F5;
  }

  50% {
    border-color: #F9F9FA;
  }

  100% {
    border-color: #F2F3F5;
  }
}

// lds spinner element
.lds-spinner {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 auto;

  div {
    transform-origin: 14px 14px;
    animation: lds-spinner 1.2s linear infinite;

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    &:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    &:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    &:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    &:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    &:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    &:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    &:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    &:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    &:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    &:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }

    &:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 13px;
      width: 2px;
      height: 6px;
      border-radius: 20%;
      background: $text-color-standard;
    }
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
  General fadeIn animation (do not use "fadeIn" name since it can be overridden by some lib in the future)
*/
@keyframes platformFadeIn {
  0% {
      opacity: 0;
      visibility: hidden;
  }

  100% {
      opacity: 1;
      visibility: visible;
  }
}
