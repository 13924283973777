@import "src/scss/vars.scss";
// Prime Radio Button
p-radiobutton {
  padding: 1px 0;
}

.p-radiobutton {
  width: 22px;
  height: 22px;
  cursor: pointer;

  &.p-radiobutton-disabled {
    cursor: not-allowed;
  }

  .p-radiobutton-box {
    width: 22px;
    height: 22px;
    border: 2px solid;
    border-color: $unselected-light;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;

    &:hover {
      border-color: $secondary-hover;
    }

    .p-radiobutton-icon {
      width: 8px;
      height: 8px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    }

    &.p-highlight {
      background-color: $secondary-hover;
      border-color: $secondary-hover;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2),
                  0 10px 40px 0 rgba(74, 116, 255, 0.3);

      &:not(.p-disabled):hover {
        background-color: $secondary-hover;
        border-color: $secondary-hover;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2),
                    0 10px 40px 0 rgba(74, 116, 255, 0.3);
      }
    }

    &:not(.p-disabled) {

      &.p-focus {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2),
                    0 10px 40px 0 rgba(74, 116, 255, 0.3);
        border-color: $secondary-hover;
      }

      &:not(.p-highlight):hover {
        box-shadow: none;
        border-color: $secondary-hover;
      }
    }
  }

  .p-disabled {
    border-color: $disabled;
    color: $disabled;
    cursor: not-allowed;
  }
}

.p-radiobutton-label {
  font-family: $font-family-primary;
  font-weight: bold;
  color: $text-color-standard;
  font-size: 14px;
  cursor: pointer;
  margin-left: 15px;
}

.p-disabled,
.p-disabled * {
  opacity: 1;
  border-color: $disabled;
  color: $unselected-light;
  cursor: not-allowed !important;
}

// Custom radio button
/* The container */
.radio-container {
  display: block;
  padding: 1px 0;
  position: relative;
  cursor: pointer;

  &.radio-disabled {
    cursor: not-allowed;
    border-color: $disabled;

    .radio-text {

      .radio-label {
        cursor: not-allowed;
        color: $unselected-light;
      }
    }

    .custom-radio-checkmark {
      border-color: $disabled;
    }
  }

  .radio-text {
    display: inline-block;
    padding-left: 15px;
    vertical-align: middle;

    .radio-label {
      font-size: 14px;
      line-height: 17px;
      font-family: $font-family-primary;
      font-weight: bold;
      cursor: pointer;
      color: $text-color-standard;
    }
  }

  /* Hide the browser's default radio button */
  input {
    display: none;
    cursor: pointer;
  }
}

//   /* Create a custom radio button */
.custom-radio-checkmark {
  display: inline-block;
  height: 22px;
  width: 22px;
  position: relative;
  border: 2px solid $unselected-light;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
  border-radius: 50%;
  vertical-align: middle;

  &:hover {
    border-color: $secondary-hover;
  }

  &::after {
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: $white;
    content: "";
    position: absolute;
    display: none;
  }
}

.radio-container {
  /* On mouse-over, add a blue border color */
  &:not(.radio-disabled):hover input ~ .custom-radio-checkmark {
    border-color: $secondary-hover;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .custom-radio-checkmark {
    background-color: $secondary-hover;
    border-color: $secondary-hover;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2),
                  0 10px 50px 0 rgba(74, 116, 255, 0.3);
    &::after {
      display: block;
    }
  }
}