/**  =====================
      Custom css start
==========================  **/

* {
    &:focus {
        outline: none;
    }
}

a {
    &:hover {
        outline: none;
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $theme-heading-color;
    font-weight: 600;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 14px;
}

p {
    font-size: 14px;
}

b,
strong {
    font-weight: 600;
}
/* ==========  card css start  =========== */

.anim-rotate {
    animation: anim-rotate 1s linear infinite;
}
@keyframes anim-rotate {
    100% {
        transform: rotate(360deg);
    }
}

/* ==========  scrollbar End  ===========
 ==========  card css End  ===========
 ================================    Dropdown Start  ===================== */

.dropdown-menu {
    padding: 10px 0;
    // box-shadow: 0 0 4px 0 rgba(43, 43, 43, 0.25);
    // border: none;
    font-size: $theme-font-size;

    > li,
    > .dropdown-item {
        padding-left: 8px;
        padding-right: 8px;
        margin: 0 10px;
        width: auto;
        border-radius: 2px;

        > a {
            padding: 5px;
            color: $theme-font-color;

            i {
                font-size: 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
            }
        }

        &.active,
        &:active,
        &:focus,
        &:hover {
            background: $primary-color;
            color: #fff;
            box-shadow: 0 0 4px 0 rgba(43, 43, 43, 0.25);

            > a {
                background: transparent;
                color: #fff;
            }
        }
    }

    > a {
        &.active,
        &:active,
        &:focus,
        &:hover {
            background: $primary-color;
            color: #fff;
        }
    }
}
/* ================================    Dropdown End  ===================== */
@media (min-width: 1600px) {
    .container {
        max-width: 1540px;
    }
}
@media (max-width: 992px) {
    .container {
        max-width: 100%;
    }
}
/* ========================================================
 ===============     document      ======================
 ========================================================

/* Example modals */

.modal {
    z-index: 1072;

    .tooltip {
        z-index: 1073;
    }
}

.modal-backdrop {
    z-index: 20;
}

/* Example tabbable tabs */

/* Code snippets */

.highlight {
    pre {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        background-color: transparent;
        border: 0;
    }

    pre code {
        font-size: inherit;
        color: #333;
    }
}
/* =======================================================================
 ===============     Ui kit copy model style      ====================== */

/* ================================    animation start  ===================== */

.card .animated {
    animation-duration: 1s;
    border: 5px solid #fff;
    margin-bottom: 30px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}
/* ================================    animation End  ===================== */
