@import "src/scss/vars.scss";

.singleselect {

  .p-dropdown {

    .p-dropdown-panel {

      ::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: $scroll;
        border-radius: 6px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: $scrollbar-dark;
        border-radius: 6px;
      }

      .p-dropdown-items-wrapper {

        .p-dropdown-items {
          padding: 0;

          &:first-child {
            padding-top: 15px;
          }

          &:last-child {
            padding-bottom: 15px;
          }

          .p-dropdown-item {
            padding: 0px 20px 10px 20px;
            overflow: visible;

            &.p-disabled {
              
              .radio-label {
                color: #d0d3db;
              }
            }
          }
        }
      }
    }
  }
}
